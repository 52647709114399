import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Internal = props => {
  const pageTitle = "Internal View"
  const pageSlug = "internal"

  return (
    <Layout location={props.location}>
      <SEO pageTitle={pageTitle} pageSlug={pageSlug} />
      <div className="container">
        <p>{pageTitle}</p>
      </div>
    </Layout>
  )
}

export default Internal
